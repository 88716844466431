import React, { useEffect, useState } from 'react';
import smarttieLogo from '../../assets/image/smarttie-logo.svg';
import menuIcon from '../../assets/image/burger-menu.svg';
import { Link } from 'gatsby';
import NavItem from './NavItem';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Drawer from './Drawer';
import { useLocation } from '@reach/router';

const Navbar = ({ top, mode }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();

  const paths = {
    services:
      location.pathname === '/' || location.pathname === '/es/'
        ? '#services'
        : '/#services',
    industries:
      location.pathname === '/' || location.pathname === '/es/'
        ? '#industries'
        : '/#industries',
    alliances:
      location.pathname === '/' || location.pathname === '/es/'
        ? '#alliances'
        : '/#alliances',
    contact:
      location.pathname === '/' || location.pathname === '/es/'
        ? '#contact'
        : '/#contact',
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`mx-auto nav-container z-20 ${
          top ? `nav-top-${top}` : 'top-4'
        } ${isSticky ? 'scrolled-nav-container' : 'container'}`}
      >
        <nav
          className={`flex justify-between items-center px-7 py-1 sm:py-3 bg-white/20 backdrop-blur-md sticky w-full nav ${mode ===
            'light' && 'shadow-lg'} ${isSticky && 'scrolled-nav'} `}
        >
          <Link to='/'>
            <img
              src={smarttieLogo}
              alt='smarttie logo'
              className='h-12 w-[110px] sm:w-[140px]'
            />
          </Link>
          <div className={`hidden lg:flex gap-3`}>
            <NavItem href={paths.services}>{t('nav.services')}</NavItem>
            <NavItem href={paths.industries}>{t('nav.industries')}</NavItem>
            <NavItem href={paths.alliances}>{t('nav.alliances')}</NavItem>
            <NavItem href='/careers'>{t('nav.careers')}</NavItem>
            <NavItem href='/whitepapers'>{t('nav.whitepapers')}</NavItem>
          </div>
          <a
            href={paths.contact}
            className={`bg-animation-container font-semibold hover:text-white border py-2 px-4 border-primary rounded-full text-center relative overflow-hidden hidden lg:inline-block ${
              mode === 'light' ? 'text-gray-500' : 'text-white'
            }`}
          >
            <div className='bg-animation h-full w-full absolute top-0 left-0'></div>
            <span className='relative z-[2]'>{t('nav.contact')}</span>
          </a>
          <div className='block lg:hidden'>
            <img
              src={menuIcon}
              alt='menu icon'
              className={`block w-8 h-8 hover:cursor-pointer ${
                mode === 'light' ? 'icon-light' : 'icon-dark'
              } `}
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          </div>
        </nav>
      </div>
      <Drawer isVisible={openDrawer} setIsVisible={setOpenDrawer} />
    </>
  );
};

export default Navbar;
